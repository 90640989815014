import axios from 'axios';

const API_ASSOCIADO = '/api-associado';

export default {
  gerarRelatorio() {
    const url = `${API_ASSOCIADO}/associado/relatorio`;
    return axios.get(url);
  },

  associadoPorId(associadoId) {
    const url = `${API_ASSOCIADO}/associado/${associadoId}`;
    return axios.get(url);
  },


  atualizarAssociado(body, idAssociado) {
    const url = `${API_ASSOCIADO}/associado/${idAssociado}`;
    return axios.put(url, body);
  },

  dependentesPorAssociado(associadoId) {
    const url = `${API_ASSOCIADO}/dependente/${associadoId}`;
    return axios.get(url);
  },

  dependentePorId(dependenteId) {
    const url = `${API_ASSOCIADO}/dependente/${dependenteId}/detalhar`;
    return axios.get(url);
  },

  dependentePorId(dependenteId) {
    const url = `${API_ASSOCIADO}/dependente/${dependenteId}/detalhar`;
    return axios.get(url);
  },

  atualizarDependente(dependenteId, body) {
    const url = `${API_ASSOCIADO}/dependente/${dependenteId}`;
    return axios.put(url, body);
  },
};
